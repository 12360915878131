/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useSelector } from '@store';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';

import { fieldsSubject$ } from '../../state/fieldState';
import { useForceUpdate } from '../useForceUpdate/useForceUpdate';

export const useFieldsRx = () => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const subscription = fieldsSubject$.subscribe(forceUpdate);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fields: fieldsSubject$.getValue() };
};

export const useFieldsRedux = () => {
  const fields = useSelector((state) => state.fields);

  return { fields };
};

export const useFields = () => {
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');
  // This break rule of hooks, but it's a temporary solution
  if (isMaestroViewServiceReduxEnabled) {
    return useFieldsRedux();
  }
  return useFieldsRx();
};
