import { BehaviorSubject } from 'rxjs';
import { IFieldType, IWorkspace } from '@wf-mfe-maestro/api';

import { IRecordTypeWithoutFields } from '../types';
import { initialRecordType } from './initialState';

export type ReadonlyWorkspace = Readonly<IWorkspace | null>;

export type ReadonlyRecordTypeWithoutFields = Readonly<IRecordTypeWithoutFields>;

export type ReadonlyFieldTypes = Readonly<IFieldType[]>;

export const workspaceSubject$ = new BehaviorSubject<ReadonlyWorkspace>(null);

export const availableFieldTypesSubject$ = new BehaviorSubject<ReadonlyFieldTypes>([]);

export const recordTypeSubject$ = new BehaviorSubject<ReadonlyRecordTypeWithoutFields>(
  initialRecordType
);

export { initialRecordType };

export * from './viewState';
export * from './fieldState';
