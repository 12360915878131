import { Field } from '@wf-mfe-maestro/api';

import { fieldsStateSubject$, recordTypeSubject$ } from '../state';
import { getNewFieldsAfterRemove } from './getNewFieldsAfterRemove';

const deleteFieldFromRecordTypeExternalOptions = (fieldToRemove: Field) => {
  const recordType = recordTypeSubject$.getValue();
  if (recordType.externalOptions) {
    const externalOptionsFields = { ...recordType.externalOptions.fields };
    const connectedKey = Object.keys(externalOptionsFields).find(
      (fieldId) => externalOptionsFields[fieldId] === fieldToRemove.id
    );
    if (connectedKey) {
      delete externalOptionsFields[connectedKey];

      recordTypeSubject$.next({
        ...recordType,
        externalOptions: {
          ...recordType.externalOptions,
          fields: externalOptionsFields,
        },
      });
    }
  }
};

export const updateFieldsAfterRemoval = (fieldToRemove: Field) => {
  const fieldsState = fieldsStateSubject$.getValue();
  const newFields = getNewFieldsAfterRemove(fieldsState.fields, fieldToRemove);
  deleteFieldFromRecordTypeExternalOptions(fieldToRemove);
  fieldsStateSubject$.next({
    added: [],
    deleted: [],
    fields: newFields,
    recordTypeId: fieldsState.recordTypeId,
    updated: [],
  });
};
