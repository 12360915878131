import { PayloadAction } from '@reduxjs/toolkit';
import { IFieldType } from '@wf-mfe-maestro/api';

import { IRootState } from '../store';

export const setAvailableFieldTypesReducer = (
  state: IRootState,
  action: PayloadAction<IFieldType[]>
) => {
  state.availableFieldTypes = action.payload;
};
