import { IRootState } from '@store';
import { PayloadAction } from '@reduxjs/toolkit';

import { IPresentUser } from '../../types';

export const setPresentUsersReducer = (
  state: IRootState,
  { payload }: PayloadAction<IPresentUser[]>
): IRootState => ({
  ...state,
  presenceRTBE: {
    ...state.presenceRTBE,
    presentUsers: payload,
  },
});
