import { ITableViewRowOrderDTO, ViewType, api } from '@wf-mfe-maestro/api';

import { internalViewSubject$, selectedViewSubject$ } from '../../state';

export const updateTableViewRowOrder = async (rowOrderDTO: ITableViewRowOrderDTO) => {
  const selectedView = selectedViewSubject$.getValue();

  if (selectedView && selectedView.type === ViewType.TABLE) {
    const updateView = await api.view.moveTableRows(selectedView.id, rowOrderDTO);

    internalViewSubject$.next(updateView);
    return updateView;
  }
};
