import { IWorkspace } from '@wf-mfe-maestro/api';

import { workspaceSubject$ } from '../state';

export const updateWorkspaceSubject = (workspace: Partial<IWorkspace>) => {
  const oldWorkspace = workspaceSubject$.getValue();

  const newData: IWorkspace = { ...(oldWorkspace as IWorkspace), ...workspace };
  workspaceSubject$.next(newData);
};
