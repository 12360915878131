/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from '@store';
import { useEffect } from 'react';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';

import { availableFieldTypesSubject$ } from '../../state';
import { useForceUpdate } from '../useForceUpdate/useForceUpdate';

export const useAvailableFieldTypesRxJS = () => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const subscription = availableFieldTypesSubject$.subscribe(forceUpdate);
    return () => subscription.unsubscribe();
  }, [forceUpdate]);

  return {
    availableFieldTypes: availableFieldTypesSubject$.getValue(),
  };
};

export const useAvailableFieldTypesRedux = () => {
  const availableFieldTypes = useSelector((state) => state.availableFieldTypes);
  return { availableFieldTypes };
};

export const useAvailableFieldTypes = () => {
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');

  // This break rule of hooks, but it's a temporary solution
  if (isMaestroViewServiceReduxEnabled) {
    return useAvailableFieldTypesRedux();
  }
  return useAvailableFieldTypesRxJS();
};
