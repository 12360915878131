import { PayloadAction } from '@reduxjs/toolkit';
import { IRecordType } from '@wf-mfe-maestro/api';
import { merge } from 'lodash';

import { IRootState } from '../store';

export const updateRecordTypeReducer = (
  state: IRootState,
  { payload }: PayloadAction<Partial<IRecordType>>
) => {
  state.recordType = merge(state.recordType, payload);
  state.workspace?.recordTypes.map((recordType) => {
    if (recordType.id === state.recordType.id) {
      return merge(recordType, payload);
    }
    return recordType;
  });
};
