import { Field, FieldDTO } from '@wf-mfe-maestro/api';
import { cloneDeep, isArray, mergeWith } from 'lodash';

const customMergeArrays = <T, A>(originalArray: T[], newArray: A[]) => {
  if (isArray(originalArray) && isArray(newArray) && newArray.length < originalArray.length) {
    return newArray;
  }
};

export const getNewFieldsAfterUpdate = (oldFields: Field[], fieldData: FieldDTO) => {
  let updatedField: Field | null = null;
  const fieldsAfterUpdate = oldFields.map((fieldItem) => {
    if (fieldItem.id === fieldData.id) {
      updatedField = mergeWith(cloneDeep(fieldItem), fieldData, customMergeArrays) as Field;

      return updatedField;
    }
    return fieldItem;
  });

  return [fieldsAfterUpdate, updatedField] as const;
};
