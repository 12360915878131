import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ViewServiceStoreContext, slice, viewServiceStore } from '@store';

export const ViewStoreProvider: React.FC = ({ children }) => {
  useEffect(
    () => () => {
      viewServiceStore.dispatch(slice.actions.resetStore());
    },
    []
  );

  return (
    // @ts-expect-error - this is a workaround for a bug in the react-redux types, need to update react-redux version to > 9 to fix this but it requires react 18
    <Provider context={ViewServiceStoreContext} store={viewServiceStore}>
      {children}
    </Provider>
  );
};
