import {
  appendFieldReducer,
  appendFieldsReducer,
  appendViewReducer,
  deleteRecordTypeFieldReducer,
  deleteViewFromViewListReducer,
  resetFieldRTBEReducer,
  setAvailableFieldTypesReducer,
  setFieldsRTBEReducer,
  setFieldsReducer,
  setPresentUsersReducer,
  setRecordTypeReducer,
  setSelectedViewReducer,
  setShowCollaboratorsReducer,
  setViewListReducer,
  setWorkspaceReducer,
  updatePresentUsersByRecordReducer,
  updatePresentUsersReducer,
  updateRecordTypeFieldReducer,
  updateRecordTypeReducer,
  updateSelectedViewReducer,
  updateViewFromViewListReducer,
  updateWorkspaceReducer,
} from '@store/reducers';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { Field } from '@wf-mfe-maestro/api';

import { enhanceViewData } from '../utils/viewEnhancements/enhanceViewData';
import { initialState } from './initialState';
import { IRootState } from './store';
import { addRecentlyDeletedHandlers } from './slices/recentlyDeleted';

const normalizeView = (state: IRootState) => {
  if (state.selectedView.id && state.fields.length > 0 && state.recordType.primaryFieldId) {
    state.selectedView = enhanceViewData(
      state.selectedView,
      state.fieldsMap,
      state.fields,
      state.recordType.primaryFieldId
    );
  }
};

export const slice = createSlice({
  name: 'viewService',
  initialState,
  reducers: {
    setFields: setFieldsReducer,
    setRecordType: setRecordTypeReducer,
    setWorkspace: setWorkspaceReducer,
    setAvailableFieldTypes: setAvailableFieldTypesReducer,
    setSelectedView: setSelectedViewReducer,
    setViewList: setViewListReducer,
    appendField: appendFieldReducer,
    appendFields: appendFieldsReducer,
    appendView: appendViewReducer,
    deleteViewFromViewList: deleteViewFromViewListReducer,
    updateSelectedView: updateSelectedViewReducer,
    updateViewFromViewList: updateViewFromViewListReducer,
    deleteRecordTypeField: deleteRecordTypeFieldReducer,
    updateRecordType: updateRecordTypeReducer,
    updateRecordTypeField: updateRecordTypeFieldReducer,
    updateWorkspace: updateWorkspaceReducer,
    resetFieldRTBE: resetFieldRTBEReducer,
    setFieldsRTBE: setFieldsRTBEReducer,
    setShowCollaborators: setShowCollaboratorsReducer,
    setPresentUsers: setPresentUsersReducer,
    updatePresentUsers: updatePresentUsersReducer,
    updatePresentUsersByRecord: updatePresentUsersByRecordReducer,
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    addRecentlyDeletedHandlers(builder);

    // more actions that need to be handled
    const isFieldAction = isAnyOf(
      slice.actions.appendField,
      slice.actions.appendFields,
      slice.actions.setFields,
      slice.actions.deleteRecordTypeField,
      slice.actions.updateRecordTypeField,
      slice.actions.updateRecordType,
      slice.actions.setFieldsRTBE
    );
    const isActionForView = isAnyOf(slice.actions.setSelectedView, isFieldAction);

    builder
      .addMatcher(isFieldAction, (state) => {
        state.fieldsMap = state.fields.reduce((acc, field) => {
          acc[field.id] = field;
          return acc;
        }, {} as Record<string, Field>);
      })
      .addMatcher(isActionForView, normalizeView);
  },
});
