export class SequentialMap<T extends string, K> {
  private keys: T[] = [];
  private data: Record<T, K> = {} as Record<T, K>;

  set(key: T, value: K) {
    if (!this.data.hasOwnProperty(key)) {
      this.keys.push(key);
    }
    this.data[key] = value;
  }

  get(key: T): K | undefined {
    return this.data[key];
  }

  setBetween(prevKey: T, newKey: T, value: K) {
    if (!this.data.hasOwnProperty(prevKey)) {
      throw new Error(`Key "${prevKey}" does not exist.`);
    }

    const newIndex = this.keys.indexOf(prevKey) + 1;
    if (newIndex === this.keys.length) {
      this.keys.push(newKey);
    } else {
      this.keys.splice(newIndex, 0, newKey);
    }

    this.data[newKey] = value;
  }

  forEach(callback: (value: K, key: T) => void): void {
    this.keys.forEach((key) => {
      callback(this.data[key], key);
    });
  }
}
