import { useCallback, useMemo } from 'react';
import { MessageCallback, useRtbeEvents } from '@wf-mfe/event-stream';
import {
  IRTBEFieldCreatedMessage,
  IRTBEFieldDeleteMessage,
  IRTBEFieldMessage,
  IRTBEFieldUpdatedMessage,
} from 'types/fieldsRtbe';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';
import { slice, viewServiceStore } from '@store';
import { FieldType } from '@wf-mfe-maestro/api';
import { useCurrentUser } from '@wf-mfe-maestro/auth';

import { isSameSession } from '../../utils/isSameSession';
import { fieldsStateSubject$ } from '../../state';
import { getNewFieldsAfterRemove, getNewFieldsAfterUpdate } from '../../service';
import { getFieldById } from '../../stateManagement';
import { getNewFieldsAfterCreate } from '../../utils/fields/getNewFieldsAfterCreate';
import { useRefSelector } from '../useRefSelector/useRefSelector';

const RTBE_TEAM = 'maestro';
const RTBE_VIEW = 'maestro-field';

const isCreatedOperation = (message: IRTBEFieldMessage): message is IRTBEFieldCreatedMessage =>
  message.operation === 'CREATED';
const isUpdatedOperation = (message: IRTBEFieldMessage): message is IRTBEFieldUpdatedMessage =>
  message.operation === 'UPDATED';
const isDeleteOperation = (message: IRTBEFieldMessage): message is IRTBEFieldDeleteMessage =>
  message.operation === 'DELETED';

export const getIsFormulaHasError = (message: IRTBEFieldMessage): boolean => {
  if (!isUpdatedOperation(message) || message.data.type !== FieldType.FORMULA) {
    return false;
  }

  if (typeof message.data.hasError === 'boolean') {
    const formulaField = getFieldById(message.data.id);
    return formulaField?.hasError !== message.data.hasError;
  }

  return false;
};

export const useFieldRTBE = (recordTypeId: string) => {
  const currentUser = useCurrentUser();
  const fieldsRedux = useRefSelector((state) => state.fields);
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');
  const handleOnMessage: MessageCallback<IRTBEFieldMessage> = useCallback(
    (message) => {
      if (isSameSession(message.tabId) && !getIsFormulaHasError(message)) {
        return;
      }
      const fieldState = fieldsStateSubject$.getValue();
      const recordTypeId = fieldState.recordTypeId;

      switch (true) {
        case isDeleteOperation(message): {
          const field = getFieldById(message.objectId);
          if (field) {
            if (isMaestroViewServiceReduxEnabled) {
              const fieldsAfterRemove = getNewFieldsAfterRemove(fieldsRedux.current, field);
              viewServiceStore.dispatch(
                slice.actions.setFieldsRTBE({
                  fieldsRTBE: {
                    added: [],
                    updated: [],
                    deleted: [field.id],
                  },
                  fields: fieldsAfterRemove,
                })
              );
            } else {
              const fieldsAfterRemove = getNewFieldsAfterRemove(fieldState.fields, field);
              fieldsStateSubject$.next({
                recordTypeId,
                fields: fieldsAfterRemove,
                deleted: [field.id],
                added: [],
                updated: [],
              });
            }
          }
          break;
        }
        case isCreatedOperation(message): {
          // Skip adding the field if it's already created. This scenario often occurs in e2e testing mode where data created initially is encountered again upon page visit, leading to redundant creation attempts that may cause test failures.
          const isFieldExist = getFieldById(message.data.id);
          if (isFieldExist) {
            return;
          }

          if (isMaestroViewServiceReduxEnabled) {
            const fieldsAfterCreate = getNewFieldsAfterCreate(fieldsRedux.current, message.data);
            viewServiceStore.dispatch(
              slice.actions.setFieldsRTBE({
                fieldsRTBE: {
                  added: [message.data],
                  updated: [],
                  deleted: [],
                },
                fields: fieldsAfterCreate,
              })
            );
          } else {
            const fieldsAfterCreate = getNewFieldsAfterCreate(fieldState.fields, message.data);
            fieldsStateSubject$.next({
              recordTypeId,
              fields: fieldsAfterCreate,
              added: [message.data],
              updated: [],
              deleted: [],
            });
          }

          break;
        }
        case isUpdatedOperation(message): {
          if (isMaestroViewServiceReduxEnabled) {
            const [fieldsAfterUpdate, updatedField] = getNewFieldsAfterUpdate(
              fieldsRedux.current,
              message.data
            );
            viewServiceStore.dispatch(
              slice.actions.setFieldsRTBE({
                fieldsRTBE: {
                  updated: updatedField ? [updatedField] : [],
                  added: [],
                  deleted: [],
                },
                fields: fieldsAfterUpdate,
              })
            );
          } else {
            const [fieldsAfterUpdate, updatedField] = getNewFieldsAfterUpdate(
              fieldsStateSubject$.getValue().fields,
              message.data
            );
            fieldsStateSubject$.next({
              recordTypeId,
              fields: fieldsAfterUpdate,
              updated: updatedField ? [updatedField] : [],
              added: [],
              deleted: [],
            });
          }

          break;
        }
        default:
          break;
      }
    },
    [fieldsRedux, isMaestroViewServiceReduxEnabled]
  );

  const objectsToListen = useMemo(
    () => [
      {
        objectCode: 'FIELD',
        filter: {
          operation: '*',
          fieldName: 'parentId',
          fieldValue: recordTypeId,
        },
      },
    ],
    [recordTypeId]
  );

  const closeCallback = useCallback(() => {
    //
  }, []);

  return useRtbeEvents(RTBE_TEAM, RTBE_VIEW, handleOnMessage, closeCallback, objectsToListen, {
    wfImsOrgId: currentUser.wfImsOrgId || '',
  });
};
