import { FieldDTO } from '@wf-mfe-maestro/api';
import { cloneDeep, merge } from 'lodash';
import { ReadonlyField } from 'state';

export const getNewFieldsAfterUpdate: (
  oldFields: ReadonlyField[],
  fieldData: FieldDTO
) => [ReadonlyField[], ReadonlyField | null] = (
  oldFields: ReadonlyField[],
  fieldData: FieldDTO
) => {
  let updatedField = null;
  const fieldsAfterUpdate = oldFields.map((fieldItem) => {
    if (fieldItem.id === fieldData.id) {
      updatedField = merge(cloneDeep(fieldItem), fieldData);
      return updatedField;
    }
    return fieldItem;
  });
  return [fieldsAfterUpdate, updatedField];
};
