/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from 'react';
import { distinctUntilChanged, map } from 'rxjs';
import { deepEqual } from 'fast-equals';
import { IRootState, useSelector } from '@store';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';

import { recordTypeSubject$ } from '../../state';
import { IRecordTypeWithoutFields } from '../../types';

type RecordTypeSelectorCb<T> = (recordType: IRecordTypeWithoutFields) => T;

export const useRecordTypeDataRx = <T = IRecordTypeWithoutFields,>(
  selectorCb?: RecordTypeSelectorCb<T>
): { recordType: T } => {
  const defaultSelector = useRef<RecordTypeSelectorCb<T>>((value) => value as T).current;
  const selector = selectorCb ?? defaultSelector;

  const [recordType, setRecordType] = useState<T>(selector(recordTypeSubject$.getValue()));

  useEffect(() => {
    const subscription = recordTypeSubject$
      .pipe(map(selector))
      .pipe(distinctUntilChanged(deepEqual))
      .subscribe((mappedRecordType) => {
        setRecordType(mappedRecordType);
      });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { recordType };
};

export const useRecordTypeDataRedux = <T = IRecordTypeWithoutFields,>(
  selectorCb?: RecordTypeSelectorCb<T>
): { recordType: T } => {
  const defaultSelector = useRef<RecordTypeSelectorCb<T>>((value) => value as T).current;
  const selector = selectorCb ?? defaultSelector;

  const recordType = useSelector((state: IRootState) => selector(state.recordType), deepEqual);

  return { recordType };
};

export const useRecordTypeData = <T = IRecordTypeWithoutFields,>(
  selector?: RecordTypeSelectorCb<T>
) => {
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');

  if (isMaestroViewServiceReduxEnabled) {
    return useRecordTypeDataRedux(selector);
  }
  return useRecordTypeDataRx(selector);
};
