import { api } from '@wf-mfe-maestro/api';

import { IRecordTypeWithoutFields } from '../types';
import { updateRecordTypeStateWithWorkspace } from './updateRecordTypeStateWithWorkspace';

export const updateRecordType = async (newRecordType: IRecordTypeWithoutFields) => {
  updateRecordTypeStateWithWorkspace(newRecordType);

  try {
    await api.recordType.update(newRecordType.id, newRecordType);
  } catch (error) {
    console.error(error);
  }
};
