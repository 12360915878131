import { IRecordType, IWorkspace } from '@wf-mfe-maestro/api';

import { IMinimalView } from '../types/view';
import { DEFAULT_VIEW_ID, LOCAL_STORAGE_VIEW_SAVED_KEY } from '../constants';

export const getViewsMapKey = (workspaceId: IWorkspace['id'], recordTypeId: IRecordType['id']) =>
  `${workspaceId}_${recordTypeId}_view`;

type ViewMapLocalStorage = Record<string, IMinimalView>;

export const getAllSavedViewsFromLocalStorage = (): ViewMapLocalStorage => {
  const views = localStorage.getItem(LOCAL_STORAGE_VIEW_SAVED_KEY);
  const viewsMap: ViewMapLocalStorage = views ? JSON.parse(views) : {};
  return viewsMap;
};

export const saveViewToLocalStorage = ({
  workspaceId,
  recordTypeId,
  view,
}: {
  workspaceId: IWorkspace['id'];
  recordTypeId: IRecordType['id'];
  view: IMinimalView;
}) => {
  if (view.id === DEFAULT_VIEW_ID) {
    return;
  }
  const viewsMap = getAllSavedViewsFromLocalStorage();
  const viewMapKey = getViewsMapKey(workspaceId, recordTypeId);
  viewsMap[viewMapKey] = {
    id: view.id,
    type: view.type,
    filter: view.filter,
    name: view.name,
    grouping: view.grouping,
  };
  localStorage.setItem(LOCAL_STORAGE_VIEW_SAVED_KEY, JSON.stringify(viewsMap));
};

export const getViewIdFromLocalStorage = ({
  workspaceId,
  recordTypeId,
}: {
  workspaceId: IWorkspace['id'];
  recordTypeId: IRecordType['id'];
}): string => {
  const viewsMap = getAllSavedViewsFromLocalStorage();
  const viewMapKey = getViewsMapKey(workspaceId, recordTypeId);
  const minimalViewFromLS: IMinimalView = viewsMap[viewMapKey];
  return minimalViewFromLS ? minimalViewFromLS.id : DEFAULT_VIEW_ID;
};

export const getMinimalViewFromLocalStorage = ({
  workspaceId,
  recordTypeId,
}: {
  workspaceId: IWorkspace['id'];
  recordTypeId: IRecordType['id'];
}) => {
  const viewsMap = getAllSavedViewsFromLocalStorage();
  const viewMapKey = getViewsMapKey(workspaceId, recordTypeId);
  const minimalViewFromLS = viewsMap[viewMapKey];
  return minimalViewFromLS ? minimalViewFromLS : undefined;
};
