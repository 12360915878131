import { IRecordType, api } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const getRecordType = async (recordTypeId: IRecordType['id']) => {
  const recordType = await api.recordType.get(recordTypeId);
  const { fields, ...newRecordType } = recordType;

  // this validation is performed because there are situations where, the getRecordType function is called, but before it completes, the record type title is updated. Therefore, when we receive the response from getRecordType, it replaces and sets the previous value.
  const { displayName, id } = viewServiceStore.getState().recordType;

  if (id === recordType.id && displayName) {
    newRecordType.displayName = displayName;
  }

  viewServiceStore.dispatch(slice.actions.setRecordType(newRecordType));
  viewServiceStore.dispatch(slice.actions.setFields(fields));
};
