import { IWorkspace, api } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const getWorkspace = async (
  workspaceId: IWorkspace['id'],
  workspaceFields: string[] = []
) => {
  const workspace = await api.workspace.get(workspaceId, [...workspaceFields]);
  viewServiceStore.dispatch(slice.actions.setWorkspace(workspace));
};
