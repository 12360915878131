import { IRootState } from '@store';
import { PayloadAction } from '@reduxjs/toolkit';

import { IPresentUser } from '../../types';

export const updatePresentUsersReducer = (
  state: IRootState,
  action: PayloadAction<IPresentUser[]>
): IRootState => {
  const presentUsers = action.payload.reduce(
    (acc, user) => {
      const existingUserIndex = state.presenceRTBE.presentUsers.findIndex(
        (presentUser) => presentUser.id === user.id
      );

      if (existingUserIndex !== -1) {
        acc[existingUserIndex] = { ...state.presenceRTBE.presentUsers[existingUserIndex], ...user };
      } else {
        acc.push(user);
      }
      return acc;
    },
    [...state.presenceRTBE.presentUsers]
  );

  return {
    ...state,
    presenceRTBE: {
      ...state.presenceRTBE,
      presentUsers,
    },
  };
};

export const updatePresentUsersByRecordReducer = (
  state: IRootState,
  action: PayloadAction<Record<string, IPresentUser[]>>
): IRootState => ({
  ...state,
  presenceRTBE: {
    ...state.presenceRTBE,
    presentUsersByRecord: action.payload,
  },
});
