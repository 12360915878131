import { PayloadAction } from '@reduxjs/toolkit';
import { IWorkspace } from '@wf-mfe-maestro/api';
import { merge } from 'lodash';

import { IRootState } from '../store';

export const updateWorkspaceReducer = (
  state: IRootState,
  { payload }: PayloadAction<Partial<IWorkspace>>
) => {
  state.workspace = merge(state.workspace, payload);
};
