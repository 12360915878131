import { PayloadAction } from '@reduxjs/toolkit';
import { IView } from '@wf-mfe-maestro/api';

import { IRootState } from '../store';

export const deleteViewFromViewListReducer = (
  state: IRootState,
  action: PayloadAction<IView['id']>
) => {
  state.viewList = state.viewList.filter((view) => view.id !== action.payload);
};
