import { Field, IRecordType, IView, IWorkspace } from '@wf-mfe-maestro/api';
import { getTreatmentIsEnabled } from '@wf-mfe/toggles';

import * as reduxState from './stateManagementRedux';
import * as rxState from './stateManagementRx';

let isMaestroViewServiceReduxEnabled = false;

// TODO: will be remove entire file after all consumers are migrated to redux
getTreatmentIsEnabled('maestro-view-service-redux').then((isEnabled) => {
  isMaestroViewServiceReduxEnabled = isEnabled;
});

export const updateRecordTypeState = (recordType: IRecordType) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.updateRecordTypeState(recordType);
  }

  return rxState.updateRecordTypeState(recordType);
};

export const getRecordTypeState = () => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.getRecordTypeState();
  }

  return rxState.getRecordTypeState();
};

export const updateWorkspaceState = (workspace: IWorkspace) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.updateWorkspaceState(workspace);
  }

  return rxState.updateWorkspaceState(workspace);
};

export const getWorkspaceState = () => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.getWorkspaceState();
  }

  return rxState.getWorkspaceState();
};

export const updateFieldsState = (fields: Field[]) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.updateFieldsState(fields);
  }

  return rxState.updateFieldsState(fields);
};

export const getFieldsState = () => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.getFieldsState();
  }

  return rxState.getFieldsState();
};

export const updateViewListState = (views: IView[]) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.updateViewListState(views);
  }

  return rxState.updateViewListState(views);
};

export const getViewListState = () => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.getViewListState();
  }

  return rxState.getViewListState();
};

export const getSelectedViewState = () => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.getSelectedViewState();
  }

  return rxState.getSelectedViewState();
};

export const updateSelectedViewState = (view: IView) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.updateSelectedViewState(view);
  }

  return rxState.updateSelectedViewState(view);
};

export const subscribeToAddedField = (callback: (fields: Field[]) => void) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.subscribeToAddedField(callback);
  }

  return rxState.subscribeToAddedField(callback);
};

export const subscribeToUpdatedFields = (callback: (fields: Field[]) => void) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.subscribeToUpdatedFields(callback);
  }

  return rxState.subscribeToUpdatedFields(callback);
};

export const getFieldById = (fieldId: string) => {
  if (isMaestroViewServiceReduxEnabled) {
    return reduxState.getFieldById(fieldId);
  }

  return rxState.getFieldById(fieldId);
};
