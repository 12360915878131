import { recordTypeSubject$, workspaceSubject$ } from '../state';
import { IRecordTypeWithoutFields } from '../types';

export const updateRecordTypeStateWithWorkspace = (newRecordType: IRecordTypeWithoutFields) => {
  recordTypeSubject$.next(newRecordType);
  const workspace = workspaceSubject$.getValue();

  if (workspace) {
    const newRecordTypes = workspace.recordTypes.map((recordTypeItem) => {
      if (recordTypeItem.id === newRecordType.id) {
        return newRecordType;
      }
      return recordTypeItem;
    });

    // @ts-expect-error since now we can't get data with partial options, will disable typechecks
    workspaceSubject$.next({ ...workspace, recordTypes: newRecordTypes || [] });
  }
};
