import { api } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

import { IRecordTypeWithoutFields } from '../types';

export const updateRecordType = async (newRecordType: IRecordTypeWithoutFields) => {
  viewServiceStore.dispatch(slice.actions.updateRecordType(newRecordType));

  await api.recordType.update(newRecordType.id, newRecordType);
};
