import { IView, api } from '@wf-mfe-maestro/api';

import { internalViewSubject$, selectedViewSubject$, viewListSubject$ } from '../../state';

const updateCurrentViewSubject = (newData: Partial<IView>) => {
  const selectedView = selectedViewSubject$.getValue();

  const updatedData = { ...selectedView, ...newData, updatedAt: new Date().toISOString() } as IView;

  internalViewSubject$.next(updatedData);
  const oldViewList = viewListSubject$.getValue();
  const newViewList = oldViewList.map((viewItem) => {
    if (viewItem.id === updatedData.id) {
      return updatedData;
    }
    return viewItem;
  });
  viewListSubject$.next(newViewList);
  return updatedData;
};

/**
 * Updates the current view with the provided new data.
 *
 * @param newData - An object containing the new data for the view. This is a partial
 *                  IView object, so you only need to include the properties that you want to update.
 * @param skipApiCall - An optional boolean. If true, the function will only update the
 *                          view subject and will not make an API call to update the view on the server.
 *                          Default is false.
 * @example
 * updateCurrentView({ name: 'New View Name' });
 */
export const updateCurrentView = async (newData: Partial<IView>, skipApiCall?: boolean) => {
  const updatedView = updateCurrentViewSubject(newData);

  if (!skipApiCall) {
    return await api.view.update(updatedView?.id, updatedView);
  }
};
