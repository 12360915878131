import { PayloadAction } from '@reduxjs/toolkit';

import { IRootState } from '../store';
import { IRecordTypeWithoutFields } from '../../types';

export const setRecordTypeReducer = (
  state: IRootState,
  action: PayloadAction<IRecordTypeWithoutFields>
) => {
  state.recordType = action.payload;
};
