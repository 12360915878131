import {
  fieldsSubject$,
  initialRecordType,
  internalViewSubject$,
  recordTypeSubject$,
  selectedViewSubject$,
  viewListSubject$,
  workspaceSubject$,
} from '../state';

export const resetViewServiceState = () => {
  // reset the subjects to initial value
  workspaceSubject$.next(null);
  recordTypeSubject$.next(initialRecordType);
  // @ts-expect-error pass an empty object to satisfy the type
  internalViewSubject$.next({});
  // @ts-expect-error pass an empty object to satisfy the type
  selectedViewSubject$.next({});
  viewListSubject$.next([]);
  fieldsSubject$.next([]);
};
