import { Field, FieldType, ReferenceField } from '@wf-mfe-maestro/api';

export const getNewFieldsAfterRemove = (fields: Field[], fieldToRemove: Field) => {
  let affectedLookupIds = new Set<string>();
  if (fieldToRemove.type === FieldType.REFERENCE) {
    affectedLookupIds = new Set(
      fieldToRemove.referenceOptions.lookupFields.map((item) => item.fieldId)
    );
  }

  let newFields = fields.filter((fieldItem) => {
    if (fieldToRemove.type === FieldType.REFERENCE && fieldItem.type === FieldType.LOOKUP) {
      const isLookupAffected = fieldToRemove.id === fieldItem.lookupOptions.referenceFieldId;
      if (isLookupAffected) {
        return !affectedLookupIds.has(fieldItem.linkedField.id);
      }
    }
    if (fieldItem.id !== fieldToRemove.id) {
      return true;
    }
  });

  if (fieldToRemove.type === FieldType.LOOKUP) {
    const referenceField: ReferenceField | undefined = fields.find(
      (field): field is ReferenceField =>
        field.type === FieldType.REFERENCE &&
        field.id === fieldToRemove.lookupOptions.referenceFieldId
    );

    if (referenceField) {
      const updatedLookupFields = referenceField.referenceOptions.lookupFields.filter(
        (lookupField) => lookupField.fieldId !== fieldToRemove.linkedField.id
      );

      const updatedReferenceField = {
        ...referenceField,
        referenceOptions: { ...referenceField.referenceOptions, lookupFields: updatedLookupFields },
      };

      newFields = newFields.map((fieldItem) => {
        if (fieldItem.id === referenceField.id) {
          return updatedReferenceField;
        }
        return fieldItem;
      });
    }
  }

  return newFields;
};
