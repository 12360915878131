import { ViewDTO, api, emitter } from '@wf-mfe-maestro/api';

import { viewListSubject$ } from '../../state';

export const createView = async (viewDTO: ViewDTO) => {
  const newView = await api.view.create(viewDTO);
  const newViewList = [...viewListSubject$.getValue(), newView];
  viewListSubject$.next(newViewList);
  emitter.emit('VIEW_CREATED', newView);
  return newView;
};
