import { getTreatmentIsEnabled } from '@wf-mfe/toggles';
import { viewServiceStore } from '@store';

import { recordTypeSubject$ } from '../state';

let isMaestroViewServiceReduxEnabled = false;

getTreatmentIsEnabled('maestro-view-service-redux').then((isEnabled) => {
  isMaestroViewServiceReduxEnabled = isEnabled;
});

export const checkFieldIsPrimary = (fieldId: string) =>
  isMaestroViewServiceReduxEnabled
    ? viewServiceStore.getState().recordType.primaryFieldId === fieldId
    : recordTypeSubject$.getValue().primaryFieldId === fieldId;
