import { IListView, ITableView, ViewType } from '@wf-mfe-maestro/api';

import { ReadonlyField } from '../../../state';
import { mapColumnMetaData } from './mapColumnMetaData';

export const formatTableViewMetaData = (
  fields: ReadonlyField[],
  metadata: ITableView['metadata'] | IListView['metadata'],
  fieldMap: Record<string, ReadonlyField>,
  primaryFieldId: string
): ITableView['metadata'] => ({
  ...metadata,
  type: ViewType.TABLE,
  rowOrder: metadata && 'rowOrder' in metadata ? metadata.rowOrder : [],
  rowHeight: metadata && 'rowHeight' in metadata ? metadata.rowHeight : undefined,
  showThumbnail: metadata?.showThumbnail || false,
  columnMetadata: mapColumnMetaData(
    fields,
    metadata?.columnMetadata || [],
    fieldMap,
    primaryFieldId
  ),
});
