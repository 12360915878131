/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useSelector } from '@store';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';

import { viewListSubject$ } from '../../../state';
import { useForceUpdate } from '../../useForceUpdate/useForceUpdate';

export const useViewListDataRx = () => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const subscription = viewListSubject$.subscribe(forceUpdate);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { viewList: viewListSubject$.getValue() };
};

export const useViewListDataRedux = () => {
  const viewList = useSelector((state) => state.viewList);
  return { viewList };
};

export const useViewListData = () => {
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');

  if (isMaestroViewServiceReduxEnabled) {
    return useViewListDataRedux();
  }
  return useViewListDataRx();
};
