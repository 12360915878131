import { FieldDTO, api, emitter } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const createRecordTypeFields = async (fieldsData: FieldDTO[]) => {
  try {
    const recordTypeId = viewServiceStore.getState().recordType.id;
    const fields = await api.field.bulkCreate(fieldsData, recordTypeId);
    emitter.emit('FIELDS_CREATED', fields);

    viewServiceStore.dispatch(slice.actions.appendFields(fields));
    return fields;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
