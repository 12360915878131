import { IWorkspace, api } from '@wf-mfe-maestro/api';

import { workspaceSubject$ } from '../state';

export const getWorkspace = async (
  workspaceId: IWorkspace['id'],
  workspaceFields: string[] = []
) => {
  const workspace = await api.workspace.get(workspaceId, [...workspaceFields]);
  workspaceSubject$.next(workspace);
};
