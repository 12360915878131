import { IView, api } from '@wf-mfe-maestro/api';

import { internalViewSubject$, selectedViewSubject$ } from '../../state';

export const getView = async (viewId: IView['id']) => {
  const selectedView = selectedViewSubject$.getValue();
  const view = await api.view.get(viewId);

  // If the view is already selected and the new view is older than the selected view, return the selected view
  // this is mainly to prevent the view from being overwritten by an older version of the view when we have temporary save on selectedViewSubject and the user getting the view from the server
  if (selectedView.id === view.id && new Date(view.updatedAt) < new Date(selectedView.updatedAt)) {
    return selectedView;
  }
  internalViewSubject$.next(view);

  return view;
};
