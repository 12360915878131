import { IView, api } from '@wf-mfe-maestro/api';

import { viewListSubject$ } from '../../state';

export const updateViewData = (newData: IView) => {
  const oldViewList = [...viewListSubject$.getValue()];
  const newViewList = oldViewList.map((viewItem) => {
    if (viewItem.id === newData.id) {
      return newData;
    }
    return viewItem;
  });

  viewListSubject$.next(newViewList);
};

export const updateView = async (newData: IView) => {
  updateViewData(newData);

  return await api.view.update(newData.id, newData);
};
