/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from 'react';
import { distinctUntilChanged, map } from 'rxjs';
import { deepEqual } from 'fast-equals';
import { IView } from '@wf-mfe-maestro/api';
import { IRootState, useSelector } from '@store';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';

import { ReadonlyView, selectedViewSubject$ } from '../../../state';

type ViewSelector<T> = (view: ReadonlyView) => T;

export const useSelectedViewDataRx = <T = ReadonlyView>(
  selectorCb?: ViewSelector<T>
): { selectedView: T } => {
  const defaultSelector = useRef<ViewSelector<T>>((value) => value as T).current;
  const selector = selectorCb ?? defaultSelector;

  const [selectedView, setSelectedView] = useState<T>(selector(selectedViewSubject$.getValue()));

  useEffect(() => {
    const subscription = selectedViewSubject$
      .pipe(map(selector))
      .pipe(distinctUntilChanged(deepEqual))
      .subscribe((mappedView) => {
        setSelectedView(mappedView);
      });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { selectedView };
};

export const useSelectedViewDataRedux = <T = IView>(
  selectorCb?: ViewSelector<T>
): { selectedView: T } => {
  const defaultSelector = useRef<ViewSelector<T>>((value) => value as T).current;
  const selector = selectorCb ?? defaultSelector;

  const selectedView = useSelector((state: IRootState) => selector(state.selectedView), deepEqual);

  return { selectedView };
};

export const useSelectedViewData = <T = IView>(selector?: ViewSelector<T>) => {
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');

  if (isMaestroViewServiceReduxEnabled) {
    return useSelectedViewDataRedux(selector);
  }
  return useSelectedViewDataRx(selector);
};
