import { ITableViewRowOrderDTO, ViewType, api } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const updateTableViewRowOrder = async (rowOrderDTO: ITableViewRowOrderDTO) => {
  const selectedView = viewServiceStore.getState().selectedView;

  if (selectedView && selectedView.type === ViewType.TABLE) {
    const updateView = await api.view.moveTableRows(selectedView.id, rowOrderDTO);

    viewServiceStore.dispatch(slice.actions.setSelectedView(updateView));
    return updateView;
  }
};
