import { FieldDTO, FieldType, api } from '@wf-mfe-maestro/api';

import { fieldsStateSubject$, recordTypeSubject$ } from '../state';
import { getNewFieldsAfterUpdate } from './getNewFieldsAfterUpdate';

export const updateRecordTypeField = async (fieldData: FieldDTO) => {
  const fieldsState = fieldsStateSubject$.getValue();

  try {
    const [fieldsAfterUpdate] = getNewFieldsAfterUpdate(fieldsState.fields, fieldData);
    fieldsStateSubject$.next({
      ...fieldsState,
      fields: fieldsAfterUpdate,
    });
    const updatedField = await api.field.update(fieldData.id, fieldData);

    if (fieldData.type === FieldType.REFERENCE) {
      const recordType = await api.recordType.get(recordTypeSubject$.getValue().id);
      fieldsStateSubject$.next({
        ...fieldsState,
        fields: recordType.fields,
      });
    }
    return updatedField;
  } catch (error) {
    fieldsStateSubject$.next(fieldsState);
    throw error;
  }
};
