import { slice, viewServiceStore } from '@store';

import { IPresentUser } from '../types';

export const updatePresentUsers = (presentUsers: IPresentUser[]) => {
  viewServiceStore.dispatch(slice.actions.updatePresentUsers(presentUsers));
};

export const updatePresentUsersByRecord = (presentUsers: Record<string, IPresentUser[]>) => {
  viewServiceStore.dispatch(slice.actions.updatePresentUsersByRecord(presentUsers));
};
