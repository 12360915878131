import { FieldDTO } from '@wf-mfe-maestro/api';
import { PayloadAction } from '@reduxjs/toolkit';

import { IRootState } from '../store';
import { getNewFieldsAfterUpdate } from '../../utils/fields/getNewFieldsAfterUpdate';

export const updateRecordTypeFieldReducer = (
  state: IRootState,
  { payload }: PayloadAction<FieldDTO>
) => {
  const [newFields] = getNewFieldsAfterUpdate(state.fields, payload);
  state.fields = newFields;
};
