import { initialState } from '@store/initialState';
import { IRootState, useSelector } from '@store/store';
import { MutableRefObject, useRef } from 'react';
import { shallowEqual } from 'react-redux';

/**
 * Select the value from the state and store it inside the Ref (to avoid render)
 * usage: const user: MutableRefObject<User> = useRefSelector((state) => state.user);
 */
export const useRefSelector = <T,>(selector: (state: IRootState) => T): MutableRefObject<T> => {
  const ref = useRef<T>(selector(initialState));
  useSelector((state) => {
    ref.current = selector(state);
  }, shallowEqual);
  return ref;
};
