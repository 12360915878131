import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

import {
  IRTBERecordCreateMessage,
  IRTBERecordDeleteMessage,
  IRTBERecordUpdateMessage,
} from '../../types/rtbe';

/**
 * The maximum number of items that can be in the queue before it is automatically flushed.
 * @type {number}
 */
export const QUEUE_LIMIT = 500;

/**
 * The amount of time (in milliseconds) to wait before automatically flushing the queue after the last item is added.
 * @type {number}
 */
export const DEFAULT_DEBOUNCE_TIME = 500; // 0.5s

export const useRTBEQueue = (
  onFlush: (
    addPayloads: IRTBERecordCreateMessage[],
    updatePayloads: IRTBERecordUpdateMessage[],
    deletePayloads: IRTBERecordDeleteMessage[]
  ) => void,
  debounceTime: number = DEFAULT_DEBOUNCE_TIME
) => {
  const onFlushRef = useRef(onFlush);

  useEffect(() => {
    onFlushRef.current = onFlush;
  }, [onFlush]);

  const addQueue = useRef<IRTBERecordCreateMessage[]>([]);
  const updateQueue = useRef<IRTBERecordUpdateMessage[]>([]);
  const deleteQueue = useRef<IRTBERecordDeleteMessage[]>([]);

  const flushImmediate = useCallback(() => {
    onFlushRef.current(addQueue.current, updateQueue.current, deleteQueue.current);
    addQueue.current = [];
    updateQueue.current = [];
    deleteQueue.current = [];
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const flushDebounced = useCallback(
    debounce(() => flushImmediate(), debounceTime),
    [flushImmediate]
  );

  const addToQueue = useCallback(
    <T>(queue: T[], payload: T) => {
      queue.push(payload);
      if (queue.length > QUEUE_LIMIT) {
        return flushImmediate();
      }
      flushDebounced();
    },
    [flushDebounced, flushImmediate]
  );

  const add = useCallback(
    (payload: IRTBERecordCreateMessage) => {
      addToQueue(addQueue.current, payload);
    },
    [addToQueue]
  );

  const update = useCallback(
    (payload: IRTBERecordUpdateMessage) => {
      addToQueue(updateQueue.current, payload);
    },
    [addToQueue]
  );

  const deleteFn = useCallback(
    (payload: IRTBERecordDeleteMessage) => {
      addToQueue(deleteQueue.current, payload);
    },
    [addToQueue]
  );

  return { add, update, delete: deleteFn };
};
