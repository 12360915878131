import { PayloadAction } from '@reduxjs/toolkit';

import { IRootState } from '../store';

export const setFieldsRTBEReducer = (
  state: IRootState,
  action: PayloadAction<{
    fieldsRTBE: IRootState['fieldsRTBE'];
    fields: IRootState['fields'];
  }>
) => {
  state.fieldsRTBE = action.payload.fieldsRTBE;
  state.fields = action.payload.fields;
};
