import { FieldDTO, api, emitter } from '@wf-mfe-maestro/api';

import { fieldsStateSubject$, fieldsSubject$, recordTypeSubject$ } from '../state';

export const createRecordTypeField = async (fieldData: FieldDTO) => {
  try {
    const recordTypeId = recordTypeSubject$.getValue().id;
    const field = await api.field.create(fieldData, recordTypeSubject$.getValue().id);
    emitter.emit('FIELD_CREATED', field);

    fieldsStateSubject$.next({
      added: [],
      deleted: [],
      fields: [...fieldsSubject$.getValue(), field],
      recordTypeId,
      updated: [],
    });
    return field;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
