import { FieldDTO, api, emitter } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const createRecordTypeField = async (fieldData: FieldDTO) => {
  try {
    const recordTypeId = viewServiceStore.getState().recordType.id;
    const field = await api.field.create(fieldData, recordTypeId);
    emitter.emit('FIELD_CREATED', field);

    viewServiceStore.dispatch(slice.actions.appendField(field));
    return field;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
