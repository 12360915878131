import { PayloadAction } from '@reduxjs/toolkit';
import { IView } from '@wf-mfe-maestro/api';
import { merge } from 'lodash';

import { IRootState } from '../store';

export const updateViewFromViewListReducer = (
  state: IRootState,
  { payload }: PayloadAction<Partial<IView>>
) => {
  state.viewList = state.viewList.map((viewItem) => {
    if (viewItem.id === payload.id) {
      return merge(viewItem, payload);
    }
    return viewItem;
  });
};
