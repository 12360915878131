import { IView, api } from '@wf-mfe-maestro/api';

import { viewListSubject$ } from '../../state';

export const deleteView = async (viewId: IView['id']) => {
  const oldViewList = [...viewListSubject$.getValue()];
  const newViewList = oldViewList.filter((viewItem) => viewItem.id !== viewId);
  viewListSubject$.next(newViewList);
  return await api.view.delete(viewId);
};
