import { BehaviorSubject } from 'rxjs';

import { IFieldState } from './fieldState';

// This is a custom BehaviorSubject that allows us to run a function before the next value is emitted.
// TODO: This will be removed after full refactor view-service
export class FieldBehaviorSubject extends BehaviorSubject<IFieldState> {
  beforeNext: (data: IFieldState) => void;

  constructor({ beforeNext }: { beforeNext: (data: IFieldState) => void }) {
    super({
      fields: [],
      added: [],
      updated: [],
      deleted: [],
      recordTypeId: '',
    });
    this.beforeNext = beforeNext;
  }

  next(data: IFieldState) {
    if (this.beforeNext) {
      this.beforeNext(data);
    }

    super.next(data);
  }
}
