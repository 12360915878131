import { IView, api } from '@wf-mfe-maestro/api';
import { slice, viewServiceStore } from '@store';

export const updateViewData = (newData: IView) => {
  viewServiceStore.dispatch(slice.actions.updateViewFromViewList(newData));
};

export const updateView = async (newData: IView) => {
  updateViewData(newData);

  return await api.view.update(newData.id, newData);
};
