import { IView, api } from '@wf-mfe-maestro/api';

import { viewListSubject$ } from '../../state';

export const duplicateView = async (viewId: IView['id']) => {
  const newView = await api.view.duplicate(viewId);
  const newViewList = [...viewListSubject$.getValue(), newView];
  viewListSubject$.next(newViewList);
  return newView;
};
