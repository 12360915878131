import { Field, IRecordType, IView, IWorkspace } from '@wf-mfe-maestro/api';

import {
  ReadonlyField,
  addedFields$,
  fieldMapSubject$,
  fieldsStateSubject$,
  internalViewSubject$,
  recordTypeSubject$,
  selectedViewSubject$,
  updatedFields$,
  viewListSubject$,
  workspaceSubject$,
} from './state';

export const updateRecordTypeState = (recordType: IRecordType) => {
  recordTypeSubject$.next(recordType);
};

export const getRecordTypeState = () => recordTypeSubject$.getValue();

export const updateWorkspaceState = (workspace: IWorkspace) => {
  workspaceSubject$.next(workspace);
};

export const getWorkspaceState = () => workspaceSubject$.getValue();

export const updateFieldsState = (fields: Field[]) => {
  const oldValues = fieldsStateSubject$.getValue();
  fieldsStateSubject$.next({
    ...oldValues,
    fields,
  });
};

export const getFieldsState = () => fieldsStateSubject$.getValue();

export const updateSelectedViewState = (view: IView) => {
  internalViewSubject$.next(view);
};

export const getSelectedViewState = () => selectedViewSubject$.getValue();

export const updateViewListState = (views: IView[]) => {
  viewListSubject$.next(views);
};

export const getViewListState = () => viewListSubject$.getValue();

export const subscribeToAddedField = (callback: (fields: Field[]) => void) => {
  const subscription = addedFields$.subscribe(callback);
  return () => subscription.unsubscribe();
};

export const subscribeToUpdatedFields = (callback: (fields: Field[]) => void) => {
  const subscription = updatedFields$.subscribe(callback);
  return () => subscription.unsubscribe();
};

export const getFieldById = (fieldId: string): ReadonlyField =>
  fieldMapSubject$.getValue()[fieldId];
