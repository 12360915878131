import { useDispatch, useSelector } from '@store';
import {
  fetchRecentlyDeletedThunk,
  restoreRecentlyDeletedThunk,
} from '@store/slices/recentlyDeleted';

import { useRecordTypeData } from '../useRecordTypeData/useRecordTypeData';

export const useRecentlyDeleted = () => {
  const dispatch = useDispatch();
  const { recordType } = useRecordTypeData();
  const recentlyDeleted = useSelector((store) => store.recentlyDeletedRecords);

  const fetchRecentlyDeleted = () => dispatch(fetchRecentlyDeletedThunk(recordType.id)).unwrap();

  const restoreRecentlyDeleted = async (recordIds: string[]) => {
    await dispatch(
      restoreRecentlyDeletedThunk({
        recordTypeId: recordType.id,
        recordIds,
      })
    );
  };

  return {
    recentlyDeleted,
    fetchRecentlyDeleted,
    restoreRecentlyDeleted,
  };
};
