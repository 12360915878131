import { IView, ViewType } from '@wf-mfe-maestro/api';
import { ReadonlyField } from 'state';

import { polishFSG } from './polishFSG';
import { formatTableViewMetaData } from './tableView/formatTableViewMetaData';

export const enhanceViewData = (
  view: IView,
  fieldMap: Record<string, ReadonlyField>,
  fields: ReadonlyField[],
  primaryFieldId: string
): IView => {
  const correctFSG = polishFSG(view, fieldMap);

  if (view.type === ViewType.TABLE || view.type === ViewType.LIST) {
    const correctMetaData = formatTableViewMetaData(
      fields,
      view.metadata,
      fieldMap,
      primaryFieldId
    );
    return { ...view, metadata: correctMetaData, ...correctFSG } as IView;
  }

  return { ...view, ...correctFSG };
};
