/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useSelector } from '@store';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';

import { workspaceSubject$ } from '../../state';
import { useForceUpdate } from '../useForceUpdate/useForceUpdate';

export const useWorkspaceDataRx = () => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const subscription = workspaceSubject$.subscribe(forceUpdate);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { workspace: workspaceSubject$.getValue() };
};

export const useWorkspaceDataRedux = () => {
  const workspace = useSelector((state) => state.workspace);
  return { workspace };
};

export const useWorkspaceData = () => {
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');

  if (isMaestroViewServiceReduxEnabled) {
    return useWorkspaceDataRedux();
  }
  return useWorkspaceDataRx();
};
