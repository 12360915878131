import { IView } from '@wf-mfe-maestro/api';
import { BehaviorSubject } from 'rxjs';

export type ReadonlyViewList = Readonly<IView[]>;

export type ReadonlyView = Readonly<IView>;

export const viewListSubject$ = new BehaviorSubject<ReadonlyViewList>([]);

// @ts-expect-error pass an empty object to satisfy the type
export const selectedViewSubject$ = new BehaviorSubject<IView>({});

// @ts-expect-error pass an empty object to satisfy the type
export const internalViewSubject$ = new BehaviorSubject<IView>({});
