import { IView } from '@wf-mfe-maestro/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { isNil, mergeWith } from 'lodash';

import { IRootState } from '../store';

export const updateSelectedViewReducer = (
  state: IRootState,
  { payload }: PayloadAction<Partial<IView>>
) => {
  const newView = mergeWith(state.selectedView, payload, (obj, src) => {
    if (!isNil(src)) {
      return src;
    }
    return obj;
  });
  state.selectedView = newView;
  state.viewList = state.viewList.map((view) => {
    if (view.id === newView.id) {
      return newView;
    }
    return view;
  });
};
